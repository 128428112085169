import moment from 'moment';
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { confirmAlert } from 'react-confirm-alert';
import numbro from 'numbro';

import { LIMIT } from './constants';

export const isUnset = o => typeof o === 'undefined' || o === null;

export function encodeValue(val) {
	if (typeof val === 'string') {
		return val;
	}

	return JSON.stringify(val);
}

export function decodeValue(val) {
	if (typeof val === 'string') {
		try {
			return JSON.parse(val);
		} catch (_) {}
	}

	return val;
}

export const changeHTMLAttribute = (attribute, value) => {
	if (document.documentElement) {
		document.documentElement.setAttribute(attribute, value);
	}
	return true;
};

export function updateImmutable(list, payload) {
	const data = list.find(d => d.id === payload.id);
	if (data) {
		const index = list.findIndex(d => d.id === payload.id);

		return [
			...list.slice(0, index),
			{ ...data, ...payload },
			...list.slice(index + 1),
		];
	}

	return list;
}

export const notifyWithIcon = (notify, type, description, duration = 4.5) => {
	notify[type]({ message: type, description, duration });
};

export const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const antIconSync = <SyncOutlined style={{ fontSize: 24 }} spin />;

export const checkPermission = (permissions, permission) => {
	return permissions.find(p => p?.name === permission);
};

export const getSetting = (settings, slug) =>
	settings.find(s => s.slug === slug);

export function formatDate(date, format = 'DD-MMM-YYYY', has_tz = false) {
	if (!date) {
		return '--';
	}

	const offset = moment().utcOffset();

	if (has_tz) {
		const hours = Math.floor(Math.abs(offset) / 60);
		const minutes = Math.abs(offset) % 60;
		const sign = offset >= 0 ? '+' : '-';
		const tz = `${sign}${hours}:${minutes.toString().padStart(2, '0')}`;
		const datetime = moment(date).add(offset, 'minutes').format(format);

		return `${datetime} UTC${tz}`;
	}

	return moment(date).add(offset, 'minutes').format(format);
}

export function confirmAction(action, payload, type, alertText, alertHead) {
	const icons = {
		delete: 'gsqxdxog',
		restore: 'pyzlgcfb',
		export: 'nocovwne',
		import: 'dxnllioo',
		enable: 'cgzlioyf',
		disable: 'dykoqszm',
		confirm: 'usownftb',
		approve: 'cgzlioyf',
		decline: 'dykoqszm',
	};

	confirmAlert({
		customUI: ({ onClose }) => {
			const onClick = async () => {
				action(payload);
				onClose();
			};

			return (
				<div className="custom-ui p-5 text-center">
					{icons[type] && (
						<lord-icon
							src={`https://cdn.lordicon.com/${icons[type]}.json`}
							trigger="loop"
							colors="primary:#0ab39c,secondary:#f06548"
							style={{ width: '100px', height: '100px' }}
						></lord-icon>
					)}
					<div className="mt-4 text-center">
						<h4>{alertHead ? alertHead : 'Are you sure?'}</h4>
						<p className="text-muted fs-15 mb-4">
							{alertText ? alertText : 'You want to delete this item'}
						</p>
						<div className="hstack gap-2 justify-content-center remove">
							<button className="btn btn-danger" onClick={onClose}>
								No
							</button>
							<button className="btn btn-success" onClick={onClick}>
								Yes
							</button>
						</div>
					</div>
				</div>
			);
		},
	});
}

export const getQueryString = query => {
	let queries = [];

	if (query.get('length')) {
		queries = [...queries, `length=${query.get('length')}`];
	}

	if (query.get('q')) {
		queries = [...queries, `q=${query.get('q')}`];
	}

	if (query.get('from')) {
		queries = [...queries, `from=${query.get('from')}`];
	}

	if (query.get('to')) {
		queries = [...queries, `to=${query.get('to')}`];
	}

	if (query.get('brand')) {
		queries = [...queries, `brand=${query.get('brand')}`];
	}

	if (query.get('sort_by')) {
		queries = [...queries, `sort_by=${query.get('sort_by')}`];
	}

	return queries.join('&');
};

export const changeLimit = (e, query, location, navigate) => {
	let queries = [];

	if (Number(e.target.value) !== LIMIT) {
		queries = [...queries, `length=${e.target.value}`];
	}

	if (query.get('q')) {
		queries = [...queries, `q=${query.get('q')}`];
	}

	if (query.get('from')) {
		queries = [...queries, `from=${query.get('from')}`];
	}

	if (query.get('to')) {
		queries = [...queries, `to=${query.get('to')}`];
	}

	if (query.get('brand')) {
		queries = [...queries, `brand=${query.get('brand')}`];
	}

	if (query.get('sort_by')) {
		queries = [...queries, `sort_by=${query.get('sort_by')}`];
	}

	const qs = queries.length > 0 ? `?${queries.join('&')}` : '';

	navigate(`${location.pathname}${qs}`);
};

export const doSearch = (
	searchTerm,
	searchStartDate,
	searchEndDate,
	brand,
	query,
	location,
	navigate,
	sort
) => {
	let queries = [];

	if (query.get('length')) {
		queries = [...queries, `length=${query.get('length')}`];
	}

	if (searchTerm && searchTerm !== '') {
		queries = [...queries, `q=${searchTerm}`];
	}

	if (searchStartDate && searchStartDate !== '') {
		queries = [
			...queries,
			`from=${moment(searchStartDate).format('YYYYMMDD')}`,
		];
	}

	if (
		searchEndDate &&
		searchEndDate !== '' &&
		searchStartDate !== searchEndDate
	) {
		queries = [...queries, `to=${moment(searchEndDate).format('YYYYMMDD')}`];
	}

	if (brand && brand !== '') {
		queries = [...queries, `brand=${brand}`];
	}

	if (sort && sort !== '') {
		queries = [...queries, `sort_by=${sort}`];
	} else {
		queries = [...queries];
	}

	navigate(`${location.pathname}${`?${queries.join('&')}`}`);
};

export const doClearSearch = (query, location, navigate) => {
	let queries = [];

	if (query.get('length')) {
		queries = [...queries, `length=${query.get('length')}`];
	}

	if (query.get('sort_by')) {
		queries = [...queries, `sort_by=${query.get('sort_by')}`];
	}

	const qs = queries.length > 0 ? `?${queries.join('&')}` : '';

	navigate(`${location.pathname}${qs}`);
};

export const removeQuery = (query, location, navigate, item) => {
	const queries = getQueryString(query)
		.split('&')
		.filter(q => q !== item.replace(':', '='));
	const qs = queries.length > 0 ? `?${queries.join('&')}` : '';
	navigate(`${location.pathname}${qs}`);
};

export const getString = (query, brand) => {
	const sort_by = query.get('sort_by') || '';

	const querystring = getQueryString(query);
	let queries =
		querystring !== ''
			? querystring.split('&').filter(q => q !== `sort_by=${sort_by}`)
			: [];
	const has_brand = queries.find(q => q.includes('brand='));
	if (has_brand) {
		queries = queries.filter(q => !q.includes('brand='));
	}

	const qs = queries.length > 0 ? `${queries.join('&')}&${brand}` : brand;

	return sort_by !== '' ? `${qs}&sort_by=${sort_by}` : qs;
};

export const getChartColorsArray = chartId => {
	const chartElement = document.getElementById(chartId);
	if (chartElement !== null) {
		const colorAttr =
			'data-colors' +
			('-' + document.documentElement.getAttribute('data-theme') ?? '');
		let colors =
			chartElement.getAttribute(colorAttr) ??
			chartElement.getAttribute('data-colors');

		if (colors) {
			colors = JSON.parse(colors);

			return colors.map(value => {
				const newValue = value.replace(' ', '');

				if (newValue.indexOf(',') === -1) {
					const color = getComputedStyle(
						document.documentElement
					).getPropertyValue(newValue);

					if (color) {
						return color;
					} else {
						return newValue;
					}
				} else {
					const val = value.split(',');

					if (val.length === 2) {
						let rgbaColor = getComputedStyle(
							document.documentElement
						).getPropertyValue(val[0]);
						rgbaColor = `rgba(${rgbaColor},${val[1]})`;
						return rgbaColor;
					} else {
						return newValue;
					}
				}
			});
		} else {
			console.warn('data-colors attributes not found on', chartId);
		}
	}

	return undefined;
};

export const randomNumber = function (min, max) {
	return Math.floor(Math.random() * (max - min + 1) + min);
};

export const groupBy = function (xs, key) {
	return xs.reduce(function (rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};

export const formatCurrency = number =>
	numbro(number).format({ thousandSeparated: true, mantissa: 2 });

export const formatNumber = number =>
	numbro(number).format({ average: true, totalLength: 3 });

export const formatBytes = (bytes, decimals = 2) => {
	if (bytes === 0) {
		return '0 Bytes';
	}
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export function padLeft(text, num, character = '0') {
	let pad = '';

	do {
		pad += character;
	} while (pad.length < num - text.length);

	return `${pad}${text}`;
}
