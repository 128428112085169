import React from 'react';
import notification from 'antd/es/notification';

import { NotificationContext } from '../hooks/notification';

const NotificationProvider = ({ children }) => {
	const [api, contextHolder] = notification.useNotification();

	return (
		<NotificationContext.Provider value={api}>
			{contextHolder}
			{children}
		</NotificationContext.Provider>
	);
};

export default NotificationProvider;
